













































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Session} from "@/data/model/Session";
import {getSpeechRecognizer, SpeechRecognizer} from "@/services/SpeechRecognizer";

// @ts-ignore
import levenshtein from "@/util/levenshtein";

import {SessionInterface} from "@/components/session/SessionInterface";

@Component
export default class extends Vue implements SessionInterface {
  @Prop() private session!: Session;

  private currentPromptIndex = -1;

  private autoPlay = true;
  private shouldPlayAudio = true

  private speechRecognizer?: SpeechRecognizer;

  private listening = false;
  private listenTimeout?: number;
  private listenTimeoutDelay = 1000;

  private delayBetweenPrompts = 2000;

  private currentAudio?: HTMLAudioElement;

  private repeatText = "";
  private accuracy = 0;

  get prompts() {
    return this.session.prompts?.slice(0, this.currentPromptIndex + 1) ?? [];
  }

  get prompt() {
    if (this.currentPromptIndex >= 0) {
      return this.prompts[this.currentPromptIndex];
    }

    return null;
  }

  get accuracyColor() {
    if (this.listening) {
      return "gray";
    }

    if (this.accuracy >= .8) {
      return "#42b983";
    }

    if (this.accuracy > .7) {
      return "orange";
    }

    if (this.accuracy > .5) {
      return "yellow";
    }

    return "red";
  }

  @Watch('currentPromptIndex')
  onPromptChanged(index: number) {
    if (index >= 0) {
      const prompt = this.prompts[index];
      this.repeatText = "";
      if (this.shouldPlayAudio) {
        this.playAudio(prompt.url).then(() => {
          this.listen();
        });
      } else {
        this.listen();
      }
    }
  }


  mounted() {
    this.speechRecognizer =  getSpeechRecognizer();
    this.speechRecognizer?.setLanguage(this.session.language)
    this.stopListening();

    this.nextPrompt();
  }

  unmounted() {
    this.stopListening();
    this.speechRecognizer = undefined;
  }

  getAccuracy(a: string, b: string) {
    if (a.length == 0 && b.length == 0) {
      return 1;
    }

    a = a.replaceAll("。", "").trim();
    b = b.replaceAll("。", "").trim();

    const distance = levenshtein.getEditDistance(a, b);
    return 1 - distance / Math.max(a.length, b.length);
  }

  retry() {
    this.repeatText = "";
    this.listen();
  }

  listen() {
    if (this.listening) {
      return;
    }

    if (!this.speechRecognizer) {
      return;
    }

    this.speechRecognizer.setListener((results => {
      let text = "";
      let lastTranscript = "";
      for (const result of results) {
        if (result.isFinal && result[0].confidence > 0) {
          let transcript = result[0].transcript;
          if (transcript != lastTranscript) {
            text += transcript + " ";
            lastTranscript = transcript;
          }
        }
      }

      this.repeatText = text;
      this.accuracy = this.getAccuracy(this.prompt?.text ?? "", this.repeatText);

      this.resetListenTimeout();
    }));

    this.listening = true;
    this.speechRecognizer.start();
  }

  resetListenTimeout() {
    clearTimeout(this.listenTimeout);
    this.listenTimeout = setTimeout(() => {
      this.stopListening();

      if (this.autoPlay && this.accuracy >= .8) {
        setTimeout(() => {
          this.nextPrompt();
        }, this.delayBetweenPrompts);
      }


    }, this.listenTimeoutDelay);

  }

  nextPrompt() {
    this.stop();
    if (this.currentPromptIndex < this.session.promptInfo.length - 1) {
      this.currentPromptIndex++;
    }
  }

  stop() {
    if (this.currentAudio) {
      this.currentAudio.pause();
      this.currentAudio.src = "";
      this.currentAudio.load();
    }

    this.stopListening();
  }

  stopListening() {
    clearTimeout(this.listenTimeout);
    this.speechRecognizer?.stop();
    this.listening = false;
  }

  previousPrompt() {
    this.stopListening();
    if (this.currentPromptIndex > 0) {
      this.currentPromptIndex--;
    }
  }

  getColorForSpeaker(index: number) {
    return this.colors[index % this.colors.length];
  }

  // TODO this loads every time
  playAudio(url: string) {
    return new Promise((resolve, reject) => {

      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.src = "";
        this.currentAudio.load();
      }

      const audio = new Audio();
      audio.onerror = reject;
      audio.onended = resolve;
      audio.src = url;
      this.currentAudio = audio;

      audio.oncanplaythrough = () => {
        audio.play();
      }
    });
  }
}
