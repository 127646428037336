export interface SpeechRecognizer {
    start(): void;
    stop(): void;
    setLanguage(language: string): void;
    setListener(onResult: (result: SpeechRecognitionResultList) => void): void;
}

export class WebkitSpeechRecognizer implements SpeechRecognizer {
    speechRecognition: any;
    onResult?: (result: SpeechRecognitionResultList) => void;

    constructor(continuousListening = true, interimResults = true) {
        // @ts-ignore
        this.speechRecognition = new webkitSpeechRecognition();

        this.speechRecognition.continuous = continuousListening;
        this.speechRecognition.interimResults = interimResults;
        this.speechRecognition.onresult = (event: SpeechRecognitionEvent) => {
            if (this.onResult) {
                this.onResult(event.results);
            }
        }
    }

    static isEnabled() {
        // @ts-ignore
        return (window.SpeechRecognition || window.webkitSpeechRecognition) !== undefined;
    }

    setLanguage(language: string) {
        this.speechRecognition.lang = language;
    }

    start() {
        this.speechRecognition.start();
    }

    stop() {
        this.speechRecognition.stop();
    }

    setListener(onResult: (result: SpeechRecognitionResultList) => void) {
        this.onResult = onResult;
    }
}

export function getSpeechRecognizer(): SpeechRecognizer | undefined {
    if (WebkitSpeechRecognizer.isEnabled()) {
        return new WebkitSpeechRecognizer();
    }

    return undefined;
}
