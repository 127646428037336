import firebase from "firebase/app";
import "firebase/functions";
import "firebase/storage";
import {Prompt} from "@/data/model/Prompt";

const cache: {[key: string]: string} = {}

interface AudioManagerInterface {
    generateAudioForPrompt(prompt: Prompt): Promise<string>;
}

export class AudioManager implements AudioManagerInterface {

    locale: string;
    speakers: {[locale: string]: string[]} = {
        'en-US': [
            'en-US-Wavenet-H',
            'en-US-Wavenet-B',
            'en-US-Wavenet-F'
        ],
        'ja-JP': [
            'ja-JP-Wavenet-B',
            'ja-JP-Wavenet-C',
            'ja-JP-Wavenet-D'
        ],
    }

    constructor(locale: string) {
        this.locale = locale;
    }

    private hashcode(str: string) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            const character = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + character;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    async generateAudioForPrompt(prompt: Prompt) {

        const functions = firebase.functions();
        const storage = firebase.storage();

        const promptId = this.hashcode(prompt.text);

        if (cache[promptId]) {
            return cache[promptId];
        }

        const promptAudio = functions.httpsCallable('promptAudio');
        const request = {
            prompt_id: promptId,
            text: prompt.text,
            locale: this.locale,
            speaker: this.getSpeaker(prompt)
        };

        const result = await promptAudio(request);

        const path = result.data[0].path;
        const pathRef = storage.ref(path);

        const url = await pathRef.getDownloadURL();

        cache[promptId] = url;
        return url;
    }

    private getSpeaker(prompt: Prompt) {
        const speakers = this.speakers[this.locale];
        if (speakers) {
            return speakers[prompt.speaker % speakers.length];
        }

        return undefined;
    }
}
