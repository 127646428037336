






































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Session} from "@/data/model/Session";
import {SessionInterface} from "@/components/session/SessionInterface";

@Component
export default class extends Vue implements SessionInterface {
  @Prop() private session!: Session;

  private currentPromptIndex = -1;

  private autoPlay = true;
  private shouldPlayAudio = true;

  private isPlaying = false;

  private delayBetweenPrompts = 100;

  private currentAudio?: HTMLAudioElement;

  private colors = [
    "#caebec",
    "#c9ecc9"
  ]

  get prompts() {
    return this.session.prompts?.slice(0, this.currentPromptIndex + 1) ?? [];
  }

  @Watch('currentPromptIndex')
  onPromptChanged(index: number) {
    if (index >= 0 && this.autoPlay) {
      const prompt = this.prompts[index];
      if (this.shouldPlayAudio) {
        this.playAudio(prompt.url).then(() => {
          this.currentAudio = undefined;
          setTimeout(() => {
            this.nextPrompt();
          }, this.delayBetweenPrompts);
        });
      } else {
        setTimeout(() => {
          this.nextPrompt();
        }, this.delayBetweenPrompts);
      }
    }
  }

  mounted() {
    this.next();
  }

  nextPrompt() {
    if (this.currentPromptIndex < this.session.promptInfo.length - 1) {
      this.isPlaying = true;
      this.currentPromptIndex++;
    } else {
      this.stop();
    }
  }

  next() {
    this.nextPrompt();
  }

  restart() {
    this.currentPromptIndex = -1;
    this.nextPrompt();
  }

  stop() {
    clearTimeout(this.autoPlay);
    this.currentAudio?.pause();
    this.currentAudio = undefined;
    this.isPlaying = false;
  }

  previousPrompt() {
    if (this.currentPromptIndex >= 0) {
      this.currentPromptIndex--;
    }
  }

  getColorForSpeaker(index: number) {
    return this.colors[index % this.colors.length];
  }

  // TODO this loads every time
  playAudio(url: string) {
    return new Promise((resolve, reject) => {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.src = "";
        this.currentAudio.load();
      }

      if (!url) {
        return resolve();
      }

      const audio = new Audio();
      audio.onerror = reject;
      audio.onended = resolve;
      audio.src = url;
      this.currentAudio = audio;

      audio.oncanplaythrough = () => {
        audio.play();
      }

    });
  }
}
