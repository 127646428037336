import { render, staticRenderFns } from "./Converse.vue?vue&type=template&id=e4f7418e&scoped=true&"
import script from "./Converse.vue?vue&type=script&lang=ts&"
export * from "./Converse.vue?vue&type=script&lang=ts&"
import style0 from "./Converse.vue?vue&type=style&index=0&id=e4f7418e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4f7418e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VRow})
