

















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Session} from "@/data/model/Session";
import {Deck} from "@/data/model/Deck";
import {mapState} from "vuex";
import CreateDeck from "@/views/CreateDeck.vue";

@Component({
  components: {CreateDeck},
  computed: mapState([
    'decks'
  ])
})
export default class extends Vue {
  @Prop() private session!: Session;

  private decks!: { [key: string]: Deck };

  private showCreateDeck = false;

  get deckList(): Deck[] {
    if (this.decks) {
      return Object.values(this.decks);
    }

    return [];
  }

  viewDeck(deck: Deck) {
    this.$router.push(`/decks/${deck.id}`);
  }

  createDeck() {
    this.$router.push(`/create-deck`);
  }

}
