export function iconForMode(mode: string) {
    switch (mode) {
        case 'listen':
            return 'mdi-ear-hearing';
        case 'repeat':
            return 'mdi-account-voice';
        case 'converse':
            return 'mdi-comment-multiple-outline';
        case 'transcribe':
            return 'mdi-lead-pencil';
    }
}
