





































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Listen from "@/components/session/Listen.vue";
import {Session, SessionMode} from "@/data/model/Session";
import {Conversation} from "@/data/model/Conversation";
import Repeat from "@/components/session/Repeat.vue";
import {SessionInterface} from "@/components/session/SessionInterface";
import Converse from "@/components/session/Converse.vue";

@Component({
  components: {
    Listen,
    Repeat
  },
})

export default class extends Vue {

  @Prop() conversationId!: string;
  @Prop() mode!: SessionMode;

  session: Session | null = null;
  loading = true;

  get conversation(): Conversation {
    return this.$store.getters.getConversation(this.conversationId);
  }

  get sessionComponent(): SessionInterface {
    console.log(this.mode);
    switch (this.mode) {
      case SessionMode.LISTEN:
        return Listen;
      case SessionMode.REPEAT:
        return Repeat;
      case SessionMode.CONVERSE:
        return Converse;
    }
    return Listen;
  }

  mounted() {
    const {deckId, conversationId} = this.$route.params;

    const deck = this.$store.getters.getDeck(deckId);
    const conversation = this.$store.getters.getConversation(conversationId);

    if (conversation) {
      const session = Session.forConversation(deck, conversation);
      session.generate().then(() => {
        this.loading = false;
        this.session = session;
      });
    }
  }

  @Watch('conversation')
  updateLateSession() {
    if (!this.session && this.conversation) {
      const {deckId} = this.$route.params;
      const deck = this.$store.getters.getDeck(deckId);
      const session = Session.forConversation(deck, this.conversation);
      session.generate().then(() => {
        this.loading = false;
        this.session = session;
      });
    }
  }

  //
  // created() {
  //   console.log("Created")
  //   window.addEventListener('keydown', this.onKeyPress.bind(this));
  // }
  //
  // destroyed() {
  //   console.log("dfs")
  //   window.removeEventListener('keydown', this.onKeyPress.bind(this));
  // }
  //
  // onKeyPress(e) {
  //   console.log(e);
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if(e.key === "Escape") {
  //     this.quit();
  //   }
  // }



  quit() {
    const session = this.$refs.session as unknown as SessionInterface;
    session?.stop();

    // this.$router.push(`/decks/${this.$route.params.deckId}`);
    this.$router.go(-1);
  }

}
