














import {Component, Prop, Vue} from "vue-property-decorator";
import {Conversation} from "@/data/model/Conversation";
import {iconForMode} from "@/utils";

@Component({})
export default class extends Vue {
  @Prop() conversation!: Conversation
  @Prop({default: true}) showLabel!: boolean;

  private modes = [
    'listen',
    'repeat',
    'converse',
    'transcribe'
  ]

  icon(mode: string) {
    return iconForMode(mode);
  }

  study(mode: string) {
    this.$router.push({
      name: 'session',
      params: {
        deckId: this.conversation.deckId,
        mode: mode,
        conversationId: this.conversation.id
      }
    })
  }

}
