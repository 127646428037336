



























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Session, SessionPrompt} from "@/data/model/Session";
import {SessionInterface} from "@/components/session/SessionInterface";
import {getSpeechRecognizer, SpeechRecognizer} from "@/services/SpeechRecognizer";

// @ts-ignore
import levenshtein from "@/util/levenshtein";

interface ConversationPrompt extends SessionPrompt {
  speech?: string;
}


@Component
export default class extends Vue implements SessionInterface {
  @Prop() private session!: Session;

  private currentPromptIndex = -1;

  private autoPlay = true;
  private shouldPlayAudio = true;

  private isPlaying = false;

  private speechRecognizer?: SpeechRecognizer;

  private listening = false;
  private listenTimeout?: number;
  private listenTimeoutDelay = 1000;

  private delayBetweenPrompts = 1000;

  private currentAudio?: HTMLAudioElement;

  private conversationPrompts: ConversationPrompt[] = [];
  private accuracy = 0;

  private speakerIndex = 1;

  private colors = [
    "#91a5fc",
    "#f6cd69"
  ]

  get prompts() {
    return this.conversationPrompts?.slice(0, this.currentPromptIndex + 1) ?? [];
  }

  get currentPrompt() {
    return this.prompts[this.currentPromptIndex];
  }

  @Watch('currentPromptIndex')
  onPromptChanged(index: number) {
    if (index >= 0 && this.autoPlay) {
      const prompt = this.prompts[index];
      if (this.speakerIndex != prompt.speaker) {
        this.playAudio(prompt.url).then(() => {
          this.currentAudio = undefined;
          setTimeout(() => {
            if (this.autoPlay) {
              this.nextPrompt();
            }
          }, this.delayBetweenPrompts);
        });
      } else {
        this.currentPrompt.speech = "";
        this.listen();
      }
    }

  }

  mounted() {
    this.speechRecognizer = getSpeechRecognizer();
    this.speechRecognizer?.setLanguage(this.session.language);
    this.stopListening();

    this.conversationPrompts = this.session.prompts?.map(prompt => {
      return {
        ...prompt,
      }
    }) ?? [];

    this.next();
  }

  unmounted() {
    this.stopListening();
    this.speechRecognizer = undefined;
  }

  nextPrompt() {
    if (this.currentPromptIndex < this.session.promptInfo.length - 1) {
      this.isPlaying = true;
      this.currentPromptIndex++;
    } else {
      this.stop();
    }
  }

  next() {
    this.nextPrompt();
  }

  restart() {
    this.currentPromptIndex = -1;
    this.nextPrompt();
  }

  stop() {
    clearTimeout(this.autoPlay);
    this.currentAudio?.pause();
    this.currentAudio = undefined;
    this.isPlaying = false;
  }

  previousPrompt() {
    if (this.currentPromptIndex >= 0) {
      this.currentPromptIndex--;
    }
  }

  getColorForSpeaker(index: number) {
    if (index == this.speakerIndex) {
      return "#fff";
    }
    return this.colors[index % this.colors.length];
  }

  // TODO this loads every time
  playAudio(url: string) {
    return new Promise((resolve, reject) => {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.src = "";
        this.currentAudio.load();
      }

      if (!url) {
        return resolve();
      }

      const audio = new Audio();
      audio.onerror = reject;
      audio.onended = resolve;
      audio.src = url;
      this.currentAudio = audio;

      audio.oncanplaythrough = () => {
        audio.play();
      }

    });
  }

  retry() {
    if (this.currentPrompt) {
      this.currentPrompt.speech = "";
      this.listen();
    }
  }

  listen() {
    if (this.listening) {
      return;
    }

    if (!this.speechRecognizer) {
      return;
    }

    this.speechRecognizer.setListener((results => {
      let text = "";
      let lastTranscript = "";
      for (const result of results) {
        if (result.isFinal && result[0].confidence > 0) {
          const transcript = result[0].transcript;
          if (transcript != lastTranscript) {
            text += transcript + " ";
            lastTranscript = transcript;
          }
        }
      }

      const prompt = this.currentPrompt;
      prompt.speech = text;

      const index = this.conversationPrompts.indexOf(prompt);
      Vue.set(this.conversationPrompts, index, prompt);

      this.accuracy = this.getAccuracy(prompt.text ?? "", text);
      this.resetListenTimeout();
    }));

    this.listening = true;
    this.speechRecognizer.start();
    this.resetListenTimeout(5000);
  }

  get accuracyColor() {
    if (this.listening) {
      return "gray";
    }

    if (this.accuracy >= .8) {
      return "#42b983";
    }

    if (this.accuracy > .7) {
      return "orange";
    }

    if (this.accuracy > .5) {
      return "yellow";
    }

    return "red";
  }

  getAccuracy(a: string, b: string) {
    if (a.length == 0 && b.length == 0) {
      return 1;
    }

    a = a.replaceAll("。", "").trim();
    b = b.replaceAll("。", "").trim();

    const distance = levenshtein.getEditDistance(a, b);
    return 1 - distance / Math.max(a.length, b.length);
  }

  resetListenTimeout(timeout: number = this.listenTimeoutDelay) {
    console.log("reset")
    clearTimeout(this.listenTimeout);
    this.listenTimeout = setTimeout(() => {
      this.stopListening();

      if (this.autoPlay && this.accuracy >= .8) {
        setTimeout(() => {
          this.nextPrompt();
        }, this.delayBetweenPrompts);
      }


    }, timeout);

  }

  stopListening() {
    console.log("stopped");
    clearTimeout(this.listenTimeout);
    this.speechRecognizer?.stop();
    this.listening = false;
  }
}
