




























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Deck} from "@/data/model/Deck";
import {mapState} from "vuex";
import {Conversation} from "@/data/model/Conversation";
import {Prompt} from "@/data/model/Prompt";
import StudyModesList from "@/components/StudyModesList.vue";
import {AudioManager} from "@/services/AudioManager";
import {SessionPrompt} from "@/data/model/Session";

@Component({
  components: {StudyModesList},
  computed: mapState([
    'decks',
    'conversations'
  ])
})
export default class extends Vue {
  @Prop() private deckId!: string;
  @Prop() private conversationId!: string;

  private editKey: string | number | null = null;

  private decks!: { [key: string]: Deck };

  private prompts: Prompt[] = [];

  private currentAudio?: HTMLAudioElement;
  private loadingKey: string | null = null;

  private overflowMenu = false;
  private deleteDialog = false;

  private colors = [
    "#98d7d9",
    "#94d98d"
  ]

  mounted() {
    this.$watch('conversations', (state) => {
      this.prompts = this.conversation?.prompts.slice();
    }, {immediate: true});
  }

  get deck() {
    return this.$store.getters.getDeck(this.deckId);
  }

  getColorForSpeaker(index: number) {
    return this.colors[index % this.colors.length];
  }

  get conversation(): Conversation {
    return this.$store.getters.getConversation(this.conversationId);
  }

  edit(key: string) {
    this.editKey = key;
  }

  save(key: string) {
    const last = this.prompts[this.prompts.length - 1];
    if (!last.text) {
      this.prompts.pop();
    }

    this.conversation.prompts = this.prompts.slice();
    this.editKey = null;
  }

  study(conversation: Conversation, mode: string) {
    // this.$router.push({
    //   name: 'session',
    //   params: {
    //     deckId: conversation.deckId,
    //     mode: mode,
    //     conversationId: conversation.id
    //   }
    // })
  }

  newPrompt() {
    const nextSpeaker = ((this.prompts[this.prompts.length - 1]?.speaker || 0) + 1) % 2;
    this.prompts.push({
      text: "",
      speaker: nextSpeaker
    });
    this.editKey = this.prompts.length - 1;
  }

  async play(prompt: Prompt, key: string) {

    if (!prompt.text) {
      return;
    }

    this.loadingKey = key;
    const audioManager = new AudioManager(this.deck.language);
    const url = await audioManager.generateAudioForPrompt(prompt);
    await this.playAudio(url);
  }

  playAudio(url: string) {
    return new Promise<void>((resolve, reject) => {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.src = "";
        this.currentAudio.load();
      }

      if (!url) {
        return resolve();
      }

      const audio = new Audio();
      audio.onerror = reject;
      // @ts-ignore
      audio.onended = resolve;
      audio.src = url;
      this.currentAudio = audio;

      audio.oncanplaythrough = () => {
        this.loadingKey = null;
        audio.play();
      }
    });
  }

  deleteConversation() {
    this.$store.dispatch('deleteConversation', {
      deckId: this.deckId,
      conversationId: this.conversationId
    }).then(() => {
      this.$router.push(`/decks/${this.deckId}`);
    });
  }
}
