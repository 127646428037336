import {Prompt} from "@/data/model/Prompt";
import {Conversation} from "@/data/model/Conversation";
import {Deck} from "@/data/model/Deck";
import {AudioManager} from "@/services/AudioManager";

export enum SessionMode {
    LISTEN = "listen",
    REPEAT = "repeat",
    CONVERSE = "converse",
}

export interface SessionPrompt {
    id: string;
    text: string;
    speaker: number,
    language: string;
    url: string;
}

interface SessionParams {
    title: string;
    speakers: any[];
    language: string;
    promptInfo: Prompt[];
    tagMap: { [tag: string]: string[] };
}

export class Session {
    title: string;
    speakers: any[];
    language: string;

    promptInfo: Prompt[];
    tagMap: { [key: string]: string[] };

    prompts?: SessionPrompt[];

    protected constructor({title, speakers, language, promptInfo, tagMap}: SessionParams) {
        this.title = title;
        this.speakers = speakers;
        this.language = language;
        this.promptInfo = promptInfo;
        this.tagMap = tagMap;
    }

    static forConversation(deck: Deck, conversation: Conversation) {
        return new Session({
            title: conversation.title,
            speakers: conversation.getAllSpeakers(),
            tagMap: deck.generateTagMap(),
            promptInfo: conversation.prompts,
            language: deck.language,
        });
    }

    generate() {
        const audioManager = new AudioManager(this.language);
        return Promise.all(this.promptInfo.map(async prompt => {
            const url = await audioManager.generateAudioForPrompt(prompt);
            return {
                ...prompt,
                language: this.language,
                url: url,
            } as SessionPrompt;
        })).then(prompts => {
            this.prompts = prompts;
        });
    }
}
