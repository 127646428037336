var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.deck)?_c('v-container',{staticClass:"deck-view"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex mb-8"},[_c('div',[_c('h5',{staticClass:"language text-uppercase text--secondary"},[_vm._v(_vm._s(_vm.deck.getLanguageDisplay()))]),_c('h2',{staticClass:"deck-name"},[_vm._v(_vm._s(_vm.deck.name))])]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"lighten-3",attrs:{"elevation":"0","icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3696006907),model:{value:(_vm.overflowMenu),callback:function ($$v) {_vm.overflowMenu=$$v},expression:"overflowMenu"}},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){$event.stopPropagation();_vm.overflowMenu = false; _vm.deleteDialog = true}}},[_c('v-list-item-title',[_vm._v("Delete deck")])],1),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delete this deck? ")]),_c('v-card-text',[_vm._v(" All conversations in this deck will be deleted. You cannot undo this action. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.deleteDialog = false; _vm.deleteDeck()}}},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex mb-4 align-center"},[_c('h3',[_vm._v("Conversations")]),_c('v-spacer'),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","hide-overlay":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"hidden-sm-and-down",attrs:{"left":""}},[_vm._v("mdi-plus")]),_c('v-icon',{staticClass:"hidden-md-and-up"},[_vm._v("mdi-plus")]),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("New Conversation")])],1)]}}],null,false,4172086877),model:{value:(_vm.showNewConversation),callback:function ($$v) {_vm.showNewConversation=$$v},expression:"showNewConversation"}},[(_vm.showNewConversation)?_c('new-conversation',{attrs:{"deck-id":_vm.deckId},on:{"close":function($event){_vm.showNewConversation = false}}}):_vm._e()],1)],1),_c('v-row',{staticClass:"conversations"},[_vm._l((_vm.conversations),function(conversation){return _c('v-col',{key:conversation.id,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"},on:{"click":function($event){return _vm.editConversation(conversation)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"conversation",class:{'lighten-1': !hover },attrs:{"rounded":"lg","flat":"","outlined":""}},[_c('v-card-title',{staticClass:"meta"},[_c('h5',{staticClass:"conversation-title"},[_vm._v(_vm._s(conversation.title))])]),_c('v-card-subtitle',[_c('p',{staticClass:"conversation-summary mb-0"},[_vm._v(_vm._s(conversation.getSummary())+" ")])])],1)]}}],null,true)})],1)}),(_vm.conversations.length < 3)?_c('v-col',{staticClass:"deck",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"},on:{"click":function($event){_vm.showNewConversation = true}}},[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"fill-height",attrs:{"rounded":"lg","outlined":""}},[_c('v-card-title',[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-card-text',[_c('h3',{staticClass:"font-weight-bold mb-6"},[_vm._v("New conversation")])])],1)],1):_vm._e()],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }