









































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Conversation} from "@/data/model/Conversation";
import {iconForMode} from "@/utils";
import NewConversation from "@/views/NewConversation.vue";

@Component({
  components: {NewConversation}
})
export default class extends Vue {
  @Prop() private deckId!: string;

  private modes = [
    'listen',
    'repeat',
    'converse',
    'transcribe'
  ]

  private showNewConversation = false;
  private overflowMenu = false;
  private deleteDialog = false;

  get deck() {
    return this.$store.getters.getDeck(this.deckId);
  }

  get conversations(): Conversation[] {
    return this.$store.getters.getConversationsForDeck(this.deckId);
  }

  icon(mode: string) {
    return iconForMode(mode);
  }

  editConversation(conversation: Conversation) {
    this.$router.push({
      name: 'conversation',
      params: {
        deckId: conversation.deckId,
        conversationId: conversation.id
      }
    })
  }

  study(conversation: Conversation, mode: string) {
    this.$router.push({
      name: 'session',
      params: {
        deckId: conversation.deckId,
        mode: mode,
        conversationId: conversation.id
      }
    });
  }

  deleteDeck() {
    this.$store.dispatch('deleteDeck', this.deckId).then(() => {
      this.$router.replace("/");
    });
  }

}
